
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leaflet-container {
  height: 100%;
  width: 100%;
  /* height: 70svh;
  width: 80svw; */
}

/* // Flip Card */
.flipWrapper {
  background-color: transparent;
  width: 270px;
  height: 150px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  @media (max-width:426px) {
    width: 150px; 
    height: 100px;
    
  }
}

/* This container is needed to position the front and back side */
.flipInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipWrapper:hover .flipInner {
  transform: rotateY(-180deg);
}

/* Position the front and back side */
.flipFront, .flipBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flipFront {
  
}

/* Style the back side */
.flipBack{
 
  transform: rotateY(-180deg);
}

.animation.show {
  animation:rotate-in-2-br-cw .8s cubic-bezier(.25,.46,.45,.94) both;
}

@keyframes rotate-in-2-br-cw{
  0%{
    transform:rotate(-45deg);
    transform-origin:100% 100%;
    opacity:0
  }
  100%{
    transform:rotate(0);
    transform-origin:100% 100%;
    opacity:1
  }
}